export enum PortfolioPageWidgetComponentIds {
  Root = '#portfolioPageWidgetRoot',
  MultiState = '#portfolioMultiStateBox',
  GalleryWidget = '#collectionsGalleryWidget',
  ListWidget = '#categoriesListWidget',
  CollectionWidget = '#collectionWidget',
}

export enum PortfolioPageWidgetStates {
  Gallery = 'galleryState',
  List = 'listState',
  Collection = 'collectionState',
}

export enum PortfolioPageWidgetEvents {
  // Change = 'change',
}

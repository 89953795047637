import model from './model';
import { CollectionsGalleryWidgetComponentIds as ComponentIds } from './config/constants';
import { CollectionsGalleryWidgetProps as Props } from './config/types';
import { Collection } from '@wix/ambassador-portfolio-collections-v1-collection/types';
import { commonImagesToWixCode } from '../../converters/mediaConverter';
import { getCollectionUrl } from '../../utils';
import { Item } from '@wix/ambassador-portfolio-project-items-v1-project-item/types';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  let components: ReturnType<typeof getComponents>;

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    galleryWidget: $w(ComponentIds.GalleyWIdget),
  });

  const getWixCodePGItems = (collections: Collection[]) => {
    const collectionCoverImages = collections.map((collection: Collection) => {
      const item: Item & { collectionSlug: string } = {
        image: collection.coverImage,
        title: collection?.title,
        collectionSlug: collection.slug || '',
      };
      return item;
    });
    return commonImagesToWixCode(collectionCoverImages);
  };

  const initByProps = (props: Props) => {
    const { data } = props;
    if (data.collections.length !== 0) {
      components.galleryWidget.items = getWixCodePGItems(data.collections);
      components.galleryWidget.onItemClicked((event) => {
        const { collectionSlug } = components.galleryWidget.items[event.itemIndex];
        flowAPI.controllerConfig.wixCodeApi.location.to &&
          flowAPI.controllerConfig.wixCodeApi.location.to(
            getCollectionUrl(flowAPI, collectionSlug),
          );
      });
    }
  };

  $widget.onPropsChanged((_, nextProps) => initByProps(nextProps));

  return {
    pageReady: async () => {
      components = getComponents();
      if (components.topContainer.id) {
        initByProps($widget.props);
      }
    },
    exports: {},
  };
});

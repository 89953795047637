export enum ProjectDetailsWidgetComponentIds {
  Root = '#projectDetailsWidgetRoot',
  Container = '#detailItemBox',
  Text = '#detailText',
  Label = '#detailLabel',
  Repeater = '#repeater',
}

export enum ProjectDetailsWidgetEvents { }
// Change = 'change',

import model from './model';
import {
  PortfolioPageWidgetComponentIds as ComponentIds,
  PortfolioPageWidgetStates as States,
} from './config/constants';
import { queryCollections } from '@wix/ambassador-portfolio-collections-v1-collection/http';
import {
  Collection,
  SortOrder,
} from '@wix/ambassador-portfolio-collections-v1-collection/types';
import { queryProjectsWithCollectionInfo } from '@wix/ambassador-portfolio-projects-v1-project/http';
import { portfolioPageViewed } from '@wix/bi-logger-portfolio-users/v2';
import { getPageViewMode } from '../../utils';

export default model.createController((controllerParams) => {
  const { $w, $widget, flowAPI } = controllerParams;
  const biLogger = flowAPI.essentials.biLoggerFactory().logger();

  let components: ReturnType<typeof getComponents>;
  let collections: Collection[] = [];

  const getComponents = () => ({
    topContainer: $w(ComponentIds.Root),
    multiStateBox: $w(ComponentIds.MultiState),
    galleryWidget: $w(ComponentIds.GalleryWidget),
    listWidget: $w(ComponentIds.ListWidget),
    collectionWidget: $w(ComponentIds.CollectionWidget),
  });

  const getCollections = async () => {
    return flowAPI.httpClient
      .request(
        queryCollections({
          query: {
            filter: { hidden: false },
            sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
          },
        }),
      )
      .then((res) => {
        const {
          data: { collections = [] },
        } = res;
        return collections;
      });
  };

  const getCollectionProjects = async (collectionId) => {
    return flowAPI.httpClient
      .request(
        queryProjectsWithCollectionInfo({
          query: {
            filter: {
              collectionId: { $eq: collectionId },
              hidden: { $eq: false },
            },
            sort: [{ fieldName: 'sortOrder', order: SortOrder.ASC }],
          },
        }),
      )
      .then((res) => {
        const {
          data: { projects = [] },
        } = res;
        return projects;
      });
  };

  const initPortfolioMSB = async (numOfCollections: number) => {
    if (numOfCollections === 1) {
      await components.multiStateBox.changeState(States.Collection);
    } else {
      await components.multiStateBox.changeState(States.Gallery);
    }
  };

  const initPortfolioPageData = async () => {
    if (collections.length === 1) {
      const projectsInCollection = await getCollectionProjects(collections[0].id);
      components.collectionWidget.data = {
        collection: collections[0],
        projects: projectsInCollection.map((p) => p.project),
      };
    } else {
      components.galleryWidget.data = { collections };
    }
  };

  const initPortfolio = async () => {
    collections = await getCollections();
    await initPortfolioMSB(collections.length);
    await initPortfolioPageData();
  };

  return {
    pageReady: async () => {
      components = getComponents();
      // check if we have components to init
      if (components.topContainer.id) {
        const isFirstTime =
          // @ts-ignore
          !flowAPI.controllerConfig?.livePreviewOptions?.compsIdsToReset;
        await initPortfolio();
        biLogger.report(
          portfolioPageViewed({
            pageName: 'portfolio',
            viewMode: getPageViewMode(flowAPI),
          }),
        );
      }
    },
    exports: {},
  };
});

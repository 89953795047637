import project1image from '../../../assets/media/project-page-presets/project-page-1.png';
import project2image from '../../../assets/media/project-page-presets/project-page-2.png';
import project3image from '../../../assets/media/project-page-presets/project-page-3.png';
import project4image from '../../../assets/media/project-page-presets/project-page-4.png';
import project5image from '../../../assets/media/project-page-presets/project-page-5.png';
import project6image from '../../../assets/media/project-page-presets/project-page-6.png';
import project7image from '../../../assets/media/project-page-presets/project-page-7.png';

export enum ProjectPageWidgetComponentIds {
  Root = '#projectPageWidgetRoot',
  ProjectWidget1 = '#projectWidget1',
}

export enum ProjectPageWidgetEvents {}

export const ProjectPageWidgetPresets = [
  {
    // 1
    id: 'externalPreset-variants-kzwh8ghy1',
    src: project1image,
    layout: {
      width: '120px',
      height: '177px',
    },
  },
  {
    // 2
    id: 'externalPreset-variants-l1oux3ak',
    src: project2image,
    layout: {
      width: '120px',
      height: '177px',
    },
  },
  {
    // 3
    id: 'externalPreset-variants-l1sxwuw6',
    src: project3image,
    layout: {
      width: '120px',
      height: '177px',
    },
  },
  {
    // 4
    id: 'externalPreset-variants-l1sxxgah',
    src: project4image,
    layout: {
      width: '120px',
      height: '177px',
    },
  },
  {
    // 5
    id: 'externalPreset-variants-l1sxxrlb',
    src: project5image,
    layout: {
      width: '120px',
      height: '177px',
    },
  },
  {
    // 6
    id: 'externalPreset-variants-l1sxy2zi',
    src: project6image,
    layout: {
      width: '120px',
      height: '178px',
    },
  },
  {
    // 7
    id: 'externalPreset-variants-l1sxz47g',
    src: project7image,
    layout: {
      width: '120px',
      height: '178px',
    },
  },
];

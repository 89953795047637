import project1image from '../../../assets/media/project-page-presets/project-page-1.png';
import project2image from '../../../assets/media/project-page-presets/project-page-2.png';
import project3image from '../../../assets/media/project-page-presets/project-page-3.png';
import project4image from '../../../assets/media/project-page-presets/project-page-4.png';
import project5image from '../../../assets/media/project-page-presets/project-page-5.png';
import project6image from '../../../assets/media/project-page-presets/project-page-6.png';
import project7image from '../../../assets/media/project-page-presets/project-page-7.png';

// temp use of project presets images

export enum CollectionPageWidgetComponentIds {
  Root = '#collectionPageWidgetRoot',
  CollectionWidget = '#collectionWidget',
  NavigationWidget = '#navigationWidget',
}
export enum CollectionPageWidgetEvents {}

export const CollectionPageWidgetPresets = [
  {
    // 1
    id: 'externalPreset-variants-kzwjby151',
    src: project1image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    // 2
    id: 'externalPreset-variants-l3h5jn83',
    src: project2image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    // 3
    id: 'externalPreset-variants-l3h5l0hl',
    src: project3image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    // 4
    id: 'externalPreset-variants-l3h5o9oq',
    src: project4image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    // 5
    id: 'externalPreset-variants-l3hcdw0i',
    src: project5image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    // 6
    id: 'externalPreset-variants-l3hce8x2',
    src: project6image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    // 7
    id: 'externalPreset-variants-l3hcet52',
    src: project7image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    // 8
    id: 'externalPreset-variants-l3hcf8zk',
    src: project1image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
  {
    // 9
    id: 'externalPreset-variants-l3hcfkii',
    src: project2image,
    layout: {
      width: '120px',
      height: '311px',
    },
  },
];
